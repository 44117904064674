<template>
  <!-- <div class="pageContol listWrap templateList">
  <div class="framePage">-->
  <div>
    <div
      class="operationControl"
      style="display: flex; flex-direction: column;align-items:flex-start"
    >
      <div
        style="margin-bottom: 15px;font-size:1rem;align-items: flex-start; padding-left:0.5rem"
        class="searchbox"
      >
       <span style="margin-right: 15px">姓名：{{ row.userName || row.sourceUserName }}</span>
        <span style="margin-right: 15px" v-if="row.idcard || row.sourceUserIdcard">身份证号：{{ row.idcard || row.sourceUserIdcard }}</span>
        <span>电话：{{ row.mobile || row.sourceUserMobile }}</span>
      </div>
      <div class="searchbox">
        <div title="试卷名称" class="searchboxItem">
          <span class="itemLabel">试卷名称:</span>
          <el-input v-model="paperName" type="text" size="small" placeholder="请输入试卷名称" clearable />
        </div>
        <div title="考试类型" class="searchboxItem">
          <span class="itemLabel">考试类型:</span>
          <el-select v-model="Testtype" placeholder="请选择" size="small" clearable>
            <el-option
              v-for="item in TesttypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed/>
          <el-table-column label="试卷名称" align="center" show-overflow-tooltip prop="paperName"  fixed />
          <el-table-column label="考试类型" align="center" show-overflow-tooltip prop="paperUserType"  width="150px">
            <template slot-scope="scope">
                {{
                $setDictionary("PAPERUSERTYPE", scope.row.paperUserType) || '无'
                }}
            </template>
          </el-table-column>
          <el-table-column label="成绩" align="center" show-overflow-tooltip prop="paperScore" width="150px" />
          <el-table-column label="是否通过" align="center" show-overflow-tooltip  width="100px">
            <template slot-scope="scope">
              {{scope.row.paperIsComplete?'是':'否'}}
            </template>
          </el-table-column>
          <el-table-column label="正确题数" align="center" show-overflow-tooltip prop="paperRight" width="150px"/>
          <el-table-column label="错误题数" align="center" show-overflow-tooltip prop="paperError" width="150px"/>
          <el-table-column label="正确率" align="center" show-overflow-tooltip width="150px">
            <span slot-scope="scope">{{Math.round(scope.row.accuracy*100)}}%</span>
          </el-table-column>
          <el-table-column label="考试时间" align="center" show-overflow-tooltip prop="submitTime"  width="150px">
            <template slot-scope="scope">
              {{ scope.row.submitTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="150px"
            fixed="right"
          >
            <div slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="see(scope.row)"
              >答题记录</el-button>
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      queryStr: "",
      dialogExamination: false,
      dialogMytestpaper: false,
      activeName: "first",
      ruleForm: {
        source: "",
        Auditstatus: "",
        Salesstatus: "",
        Trainingtype: "",
        Creattime: ""
      },
      paperName: "",
      Testtype: "",
      paperScore: 0,
      TesttypeList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "20",
          label: "课程考试"
        },
        {
          value: "30",
          label: "课后习题"
        },
        {
          value: "45",
          label: "单元测试"
        }
      ],
      row: {}
    };
  },
  computed: {},
  methods: {
    init() {
      this.row = JSON.parse(sessionStorage.getItem("row"));
      this.projectId = sessionStorage.getItem("projectIdYw")
      // this.empty();
      this.getTableHeight();
      this.getData(-1);
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userId: this.row.userId,
        projectId: this.row.projectId || this.projectId
      };
      if (this.paperName) {
        params.paperName = this.paperName;
      }
      if (this.Testtype) {
        params.paperUserType = this.Testtype;
      }
      this.doFetch({
        url: "/biz/paperUser/listPage",
        params,
        pageNum
      });
    },
    see(row){
      sessionStorage.setItem("rowJson", JSON.stringify(row));
      this.$router.push({
        path:"/web/operate/ExaminationDetails",
        query:{
          stu:'classStudent'
        }
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15 + 30;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
</style>
