<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span v-if="stu != 'JG'">
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back(-2)">班级管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">{{projectName}}-详情</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学习记录</a>
        </span>
        <span v-else>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back(-1)">班级管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学习记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave">
          <el-tab-pane label="学习记录" name="first" >
          <LearningrecordsList/>
          </el-tab-pane>
          <el-tab-pane label="考试记录" name="second">
           <ExaminationrecordsList />
          </el-tab-pane>
          <!-- <el-tab-pane label="企业列表" name="three">
            <CourseList />
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import LearningrecordsList from "@/views/classListCom/classDetailsFile/LearningrecordsList.vue";
import ExaminationrecordsList from "@/views/classListCom/classDetailsFile/ExaminationrecordsList.vue";
export default {
  name: "Learningrecords",
  components: {
    LearningrecordsList,
    ExaminationrecordsList
  },
  mixins: [List],
  data() {
    return {
      activeName: "first",
      projectName: '',
      stu:'',
      projectId:'',
    };
  },
  methods: {
    
    init() {
      this.projectName = sessionStorage.getItem("projectName");
      const query = this.$route.query;
      this.stu = this.$route.query.stu
      this.projectId =this.$route.query.projectId
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "first";
      }
    },
    beforeTabLeave(newName) {
      this.$router.replace({path:this.$route.path, query:{active:newName}});
    }
  }
};
</script>
<style lang="less"></style>
